<template>
	<page-container :page="page">
		<heading-image
			v-if="page && page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
			class="index-heading"
		>
			<template #hero-content>
				<div class="hero-content">
					<h2>{{ defaults[locale].homepage.headingTitle }}</h2>
				</div>
			</template>
			<template #video>
				<div class="video">
					<div style="padding: 56.25% 0 0; position: relative">
						<iframe
							src="https://player.vimeo.com/video/937636033?h=9c9d08822f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;muted=1&amp;controls=0&amp;loop=1"
							frameborder="0"
							allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
							style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
							title="be-41-v3"
						/>
					</div>
					<component :is="'script'" src="https://player.vimeo.com/api/player.js" />
				</div>
			</template>
		</heading-image>

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
			<template #content>
				<div class="columns column6 col-content">
					<h4 v-if="page.subtitle" class="subtitle">
						{{ page.subtitle }}
					</h4>
					<h1>{{ page.title || page.header }}</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</template>
			<template #rightContent>
				<div class="columns column6 col-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
			</template>
		</main-content>

		<section class="main-content usp">
			<div class="row align-center">
				<div
					v-for="(usp, index) in defaults[locale].homepage.uniqueSellingPoints"
					:key="index"
					class="columns column4"
				>
					<font-awesome-icon v-if="usp.icon" :icon="usp.icon" size="1x" />
					<div v-else class="usp-icon" />
					<div v-parse-links v-html="usp.content" />
				</div>
			</div>
		</section>

		<promoblocks v-if="promoblocksData.length" :items="promoblocksData" :amount="2">
			<template #header>
				<div class="columns column12 align-center">
					<h2 class="subtitle">{{ defaults[locale].homepage.sectionPromotionSubtitle }}</h2>
					<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
				</div>
			</template>
		</promoblocks>

		<contentblocks v-if="page.contentblocks && page.contentblocks.length" :items="page.contentblocks" class="index">
			<template #header>
				<div class="columns column12 align-center">
					<h2>{{ page.contentBlockContent }}</h2>
				</div>
			</template>
		</contentblocks>

		<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>

		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
#intro {
	position: relative;
	padding: 150px 0 50px;
	border-bottom: 60px solid var(--background-color-dark);

	.column6 {
		align-self: center;
	}

	.col-content {
		padding-right: 120px;
	}

	.col-image {
		padding: 0;
		position: absolute;
		inset: 100px 25px -60px 50%;
		width: auto;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.index-heading img {
	display: none !important;
}

.hero-content {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	width: 90%;
	max-width: 960px;
	color: #fff;
	z-index: 10;
	text-align: center;
	margin: -26px auto 0;

	h2 {
		color: #fff;
		font-size: calc(var(--h1-font-size) + 6px);
	}
}

.video {
	max-height: 92vh;
	overflow: hidden;
}

.usp {
	background: var(--background-color-dark);
	color: var(--secondary-background-color);

	h2,
	:deep(h2),
	h3,
	:deep(h3) {
		color: var(--secondary-background-color);
	}

	svg {
		color: #fff;
		font-size: 56px;
		margin: 10px 0 30px;
	}

	.column4 {
		.usp-icon {
			width: 88px;
			height: 88px;
			background: url('~/assets/images/usp-icon-cart.png') no-repeat center center;
			background-size: 53px;
			margin: 0 auto 30px;
		}

		&:first-child .usp-icon {
			background: url('~/assets/images/usp-icon-marker.png') no-repeat center center;
			background-size: 53px;
		}

		&:last-child .usp-icon {
			background: url('~/assets/images/usp-icon-coffee.png') no-repeat center center;
			background-size: 56px;
		}
	}
}

@media (max-width: 960px) {
	.video {
		overflow: hidden;
		width: 150%;
		margin-left: -25%;
	}

	#intro {
		padding: 50px 0;

		.col-content {
			padding: 20px;
		}

		.col-image {
			position: relative;
			padding: 0 20px;
			inset: auto;
		}
	}

	.hero-content {
		top: 58%;

		h2 {
			font-size: calc(var(--h1-font-size) - 8px);
		}
	}

	.usp .column4 {
		width: 100%;
		margin: 20px auto;
	}

	.column6 {
		padding: 25px;
	}
}
</style>
